import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';

export function DetailSchedule({ isOpen, onClose, classInfo }) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  if (!classInfo || !isOpen) return null;

  const formattedDate = moment(classInfo.dia).locale('es').format('dddd DD MMMM');

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-75 flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {classInfo.nombre_clase} - {classInfo.hora}
          </h3>
          <button
            onClick={onClose}
            className="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mt-2">
          <p className="text-sm text-gray-500">{formattedDate}</p>
          <p className="text-sm text-gray-500">Apuntados: {classInfo.apuntados}/{classInfo.aforo}</p>
          <p className="text-sm text-gray-500">Coste: {classInfo.coste_creditos} créditos</p>
        </div>
        <div className="mt-4">
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
}