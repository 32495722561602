import React, {useRef, useState, useEffect} from "react";
import logo from "../../assets/images/logo.png";
import {Field, Form, Formik, useField} from "formik";
import * as Yup from "yup";
import { authenticationService } from "../../internals/authservice/authentication.service";
import { LockClosedIcon } from "@heroicons/react/outline";
import Popup from "../../components/popups";
import {useNavigate} from "react-router-dom";
import {TermsAndConditions} from "../../internals/TermsAndConditions";
import {CheckCircleIcon} from "@heroicons/react/solid";


const TermsAndConditionsModal = ({ isOpen, onClose }) => {
  const [canClose, setCanClose] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const checkScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          setCanClose(true);
        }
      }
    };

    if (isOpen && contentRef.current) {
      contentRef.current.addEventListener('scroll', checkScroll);
    }

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener('scroll', checkScroll);
      }
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-8 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-3/4 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Condiciones generales y normas de uso de la instalación</h3>
          <div
            ref={contentRef}
            className="mt-2 px-7 py-3 text-left max-h-96 overflow-y-auto shadow-inner"
            style={{ scrollBehavior: 'smooth' }}
          >
            <TermsAndConditions/>
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onClose}
              disabled={!canClose}
              className={`px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 ${
                !canClose ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {canClose ? 'Aceptar' : 'Por favor, desplázate hasta el final para aceptar'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const CustomCheckbox = ({ children, ...props }) => {
  const [field, meta, helpers] = useField({ ...props, type: 'checkbox' });
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    if (!field.value) {
      setShowModal(true);
    }
    helpers.setValue(e.target.checked);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <label className="flex items-center">
        <input type="checkbox" {...field} onChange={handleChange} className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out" />
        <span className="ml-2 text-sm text-gray-600">{children}</span>
      </label>
      {meta.touched && meta.error ? (
        <div className="text-red-500 text-xs mt-1">{meta.error}</div>
      ) : null}
      <TermsAndConditionsModal isOpen={showModal} onClose={closeModal} />
    </div>
  );
};

export const Register = () => {
    const navigate = useNavigate()
    const popupRef = useRef();
    const [showPopup, setShowPopup] = useState(false)
    const [popupDuration, setPopupDuration] = useState(0)
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registeredEmail, setRegisteredEmail] = useState('');

    useEffect(() => {
        if (authenticationService.currentUserValue !== null) {
            navigate('/')
        }
    }, [authenticationService.currentUser])

    if (registrationSuccess) {
        return (
            <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <img
                            className="mx-auto h-30 w-auto mb-4"
                            src={logo}
                            alt="Workflow"
                        />
                        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm font-medium">
                                        Registro exitoso
                                    </p>
                                    <p className="text-sm mt-1">
                                        Por favor, verifica tu correo electrónico: {registeredEmail}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Hemos enviado un correo de confirmación. Por favor, sigue las instrucciones para activar tu cuenta.
                        </p>
                        <button
                            onClick={() => setRegistrationSuccess(false)}
                            className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Volver al inicio de sesión
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="text-center text-3xl font-extrabold text-gray-900">
                        Crear cuenta en Moai
                    </h2>
                </div>
                <Formik
                    initialValues={{
                        nombre: '',
                        apellidos: '',
                        birthday: '',
                        movil: '',
                        email: '',
                        password: '',
                        confirmPassword: '',
                        direccion: '',
                        codigo_postal: '',
                        sexo: '',
                        dni: '',
                        termsAccepted: false
                    }}
                    validationSchema={Yup.object().shape({
                        nombre: Yup.string().required('Nombre requerido'),
                        apellidos: Yup.string().required('Apellidos requeridos'),
                        birthday: Yup.date()
                            .required('Fecha de nacimiento requerida'),
                        movil: Yup.string().required('Número de móvil requerido'),
                        email: Yup.string().email('Email inválido').required('Email requerido'),
                        password: Yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres').required('Contraseña requerida'),
                        confirmPassword: Yup.string()
                            .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
                            .required('Confirmación de contraseña requerida'),
                        direccion: Yup.string().required('Dirección requerida'),
                        codigo_postal: Yup.string().required('Código postal requerido'),
                        sexo: Yup.string().required('Sexo requerido'),
                        dni: Yup.string().required('DNI requerido'),
                        termsAccepted: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones')
                    })}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();
                        authenticationService.register(values)
                            .then(
                                () => {
                                    setRegisteredEmail(values.email)
                                    setRegistrationSuccess(true)
                                },
                                error => {
                                    setSubmitting(false);
                                    setPopupDuration(5000)
                                    setStatus(true);
                                    setShowPopup(true)
                                }
                            );
                    }}
                >
                    {({ errors, touched, isSubmitting, isValid, dirty }) => (
                        <Form className="mt-8 space-y-6">
                            {showPopup && <Popup title="Error en el registro" message="Ha ocurrido un error durante el registro." error={true} duration={popupDuration} onClose={() => {}} />}

                            <div className="rounded-md shadow-sm -space-y-px-4">
                                <div className="mb-4">
                                    <label htmlFor="nombre"
                                           className="block text-sm font-medium text-gray-700 mb-1">Nombre</label>
                                    <Field name="nombre" type="text"
                                           className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                                    {errors.nombre && touched.nombre &&
                                        <div className="text-red-500 text-xs mt-1">{errors.nombre}</div>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="apellidos"
                                           className="block text-sm font-medium text-gray-700 mb-1">Apellidos</label>
                                    <Field name="apellidos" type="text"
                                           className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                                    {errors.apellidos && touched.apellidos &&
                                        <div className="text-red-500 text-xs mt-1">{errors.apellidos}</div>}
                                </div>

                                <div className="mb-4 flex space-x-4">
                                    <div className="flex-grow">
                                        <label htmlFor="birthday"
                                               className="block text-sm font-medium text-gray-700 mb-1">
                                            Fecha de nacimiento
                                        </label>
                                        <Field
                                            name="birthday"
                                            type="date"
                                            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        />
                                        {errors.birthday && touched.birthday && (
                                            <div className="text-red-500 text-xs mt-1">{errors.birthday}</div>
                                        )}
                                    </div>

                                    <div className="w-40">
                                        <label htmlFor="sexo" className="block text-sm font-medium text-gray-700 mb-1">
                                            Sexo
                                        </label>
                                        <Field
                                            as="select"
                                            name="sexo"
                                            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        >
                                            <option value="">Selecciona</option>
                                            <option value="HO">Masculino</option>
                                            <option value="MU">Femenino</option>
                                            <option value="NO">Otro</option>
                                        </Field>
                                        {errors.sexo && touched.sexo && (
                                            <div className="text-red-500 text-xs mt-1">{errors.sexo}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="dni"
                                           className="block text-sm font-medium text-gray-700 mb-1">DNI</label>
                                    <Field name="dni" type="text"
                                           className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                                    {errors.dni && touched.dni &&
                                        <div className="text-red-500 text-xs mt-1">{errors.dni}</div>}
                                </div>

                                <div className="mb-4 flex space-x-4">
                                    <div className="flex-grow">
                                        <label htmlFor="direccion"
                                               className="block text-sm font-medium text-gray-700 mb-1">Dirección</label>
                                        <Field
                                            name="direccion"
                                            type="text"
                                            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        />
                                        {errors.direccion && touched.direccion && (
                                            <div className="text-red-500 text-xs mt-1">{errors.direccion}</div>
                                        )}
                                    </div>

                                    <div className="w-24">
                                        <label htmlFor="codigo_postal"
                                               className="block text-sm font-medium text-gray-700 mb-1">
                                            C.P.
                                        </label>
                                        <Field
                                            name="codigo_postal"
                                            type="text"
                                            maxLength="5"
                                            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        />
                                        {errors.codigo_postal && touched.codigo_postal && (
                                            <div className="text-red-500 text-xs mt-1">{errors.codigo_postal}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="movil" className="block text-sm font-medium text-gray-700 mb-1">Número
                                        de teléfono</label>
                                    <Field name="movil" type="tel"
                                           className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                                    {errors.movil && touched.movil &&
                                        <div className="text-red-500 text-xs mt-1">{errors.movil}</div>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="email"
                                           className="block text-sm font-medium text-gray-700 mb-1">Correo Electrónico<span className="text-gray-500"> (Se enviará verificación)</span></label>
                                    <Field name="email" type="email"
                                           className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                                    {errors.email && touched.email &&
                                        <div className="text-red-500 text-xs mt-1">{errors.email}</div>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="password"
                                           className="block text-sm font-medium text-gray-700 mb-1">Contraseña</label>
                                    <Field name="password" type="password"
                                           className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                                    {errors.password && touched.password &&
                                        <div className="text-red-500 text-xs mt-1">{errors.password}</div>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="confirmPassword"
                                           className="block text-sm font-medium text-gray-700 mb-1">Confirmar
                                        contraseña</label>
                                    <Field name="confirmPassword" type="password"
                                           className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                                    {errors.confirmPassword && touched.confirmPassword &&
                                        <div className="text-red-500 text-xs mt-1">{errors.confirmPassword}</div>}
                                </div>

                                <div className="mb-4 p-2">
                                    <CustomCheckbox name="termsAccepted">
                                        Acepto las condiciones generales y normas de uso de la instalación
                                    </CustomCheckbox>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={isSubmitting || !(isValid && dirty)}
                                    className="group relative w-full flex justify-center py-2 px-4 border-transparent text-sm font-medium rounded-md  text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Registrarse
                                </button>
                            </div>


                            <div className="flex items-center justify-center">
                                <div className="text-sm">
                                    <a href="/login" className="font-medium text-blue-600 hover:text-blue-500">
                                        ¿Ya tienes una cuenta? Inicia sesión
                                    </a>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};