import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';

export function JoinSchedule({ isOpen, onClose, classInfo, onJoinLeave }) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  if (!classInfo || !isOpen) return null;

  const formattedDate = moment(classInfo.dia).locale('es').format('dddd DD MMMM');
  const isUserJoined = classInfo.user_inside;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-75 flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {isUserJoined ? 'Cancelar asistencia' : 'Apuntarse a clase'}
          </h3>
          <button
            onClick={onClose}
            className="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            {isUserJoined
              ? `¿Estás seguro de querer cancelar tu asistencia a ${classInfo.nombre_clase} el día ${formattedDate}?`
              : `¿Quieres apuntarte a ${classInfo.nombre_clase} el día ${formattedDate}?`}
          </p>
          <p className="text-sm font-medium text-blue-500 mt-2">
            {isUserJoined
              ? `Si la cancelación se produce 12 horas antes del inicio de la clase, se te reembolsará ${classInfo.coste_creditos} crédito${classInfo.coste_creditos > 1 ? 's' : ''}`
              : `Te costará ${classInfo.coste_creditos} crédito${classInfo.coste_creditos > 1 ? 's' : ''}`}
          </p>
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={onClose}
          >
            Cancelar
          </button>
          <button
            type="button"
            className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
              isUserJoined
                ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
                : 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
            }`}
            onClick={() => onJoinLeave(classInfo.id, isUserJoined)}
          >
            {isUserJoined ? 'Confirmar cancelación' : 'Confirmar asistencia'}
          </button>
        </div>
      </div>
    </div>
  );
}